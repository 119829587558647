<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">

          <b-card>
            <b-row v-if="base64Video" class="px-2">

              <h3>تیزر</h3>

              <video
                  class="col-12"
                  controls
                  style="max-height: 30rem;"
              >
                <source
                    size=""
                    :src="base64Video"
                    type=""
                />
              </video>

              <b-button
                  variant="danger"
                  class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="deleteTrailer"
              >
                <span class="">حذف تریلر</span>
              </b-button>

            </b-row>
          </b-card>

          <b-card>
            <b-form>
              <b-row>

                <!-- Field: fileData -->
                <b-col
                    cols="12"
                    md="4"
                >

                  <b-button
                      class="mt-2 col-12"
                      variant="warning"
                      @click="$refs.refInputEl.click()"
                  >
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @change="makeBase64Pic($event)"
                    >
                    <span class="">بارگذاری تیزر جدید</span>
                  </b-button>

                </b-col>

                <b-col
                    cols="12"
                    md="4"
                >

                  <b-button
                      variant="primary"
                      class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="upload"
                  >
                    <span class="">ثبت</span>
                  </b-button>

                </b-col>
              </b-row>
            </b-form>
          </b-card>

        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia, BOverlay,
  BRow, BTab, BTabs , BProgress ,BModal
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from "vue-select";
import axios from "axios";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductGetProductsMediasForAdminRequest} from "@/libs/Api/Product";
import {MediaDeleteByPictureUrlRequest} from "@/libs/Api/Product";
import {AccountGetSliderRequest, AccountSetSliderRequest , AccountDeleteSliderRequest} from "@/libs/Api/Account";

export default {
  title: 'تیزر سمینار - پنل ادمین مکس ',
  name: "SeminarTrailer",
  components:{
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BProgress,
    AppCollapse,
    AppCollapseItem,
    BModal,
  },
  data() {
    return {
      overlay:false,
      trailer:null,
      base64Video:null,
      pic1:null
    };
  },
  async created(){
    await this.getTrailer();
  },
  methods: {
    async getTrailer() {
      let _this = this;
      _this.overlay = true;

      let accountGetSliderRequest = new AccountGetSliderRequest(_this)
      await accountGetSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.trailer = content;
        content.forEach(item => {
          if (item.priority === 3) {
            _this.base64Video = 'https://api.maxterms.com/media/gallery/slider/'+item.pictureUrl
          }
        })
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async deleteTrailer() {
      let _this = this;
      let priority = 3;
      _this.overlay = true;

      let accountDeleteSliderRequest = new AccountDeleteSliderRequest(_this);
      accountDeleteSliderRequest.setPriority(priority)
      await accountDeleteSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload()
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0];
      _this.base64Video = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.pic1 = result;
      console.log(_this.base64Video)
    },
    async upload() {
      let _this = this;
      _this.overlay = true;

      let  data = {
          base64: _this.pic1,
          priority: 3
        }

      let accountSetSliderRequest = new AccountSetSliderRequest(_this);
      accountSetSliderRequest.setParams(data);
      await accountSetSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>

</style>